<template>
  <b-modal
    id="modal-add-edit-proceso-electoral-gasto"
    size="md"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="mostrarModal"
    dialog-class="modal-dialog-scrollable"
    v-if="mostrarModal"
    @close="handleModalClose"
  >
    <template slot="modal-title">
      <div>
        <span class="font-weight-light"
          ><i class="fa fa-file-alt fa-fw"></i>
          {{
            procesoElectoralGasto.procesoElectoralGastoId ? "Editar" : "Agregar"
          }}
          Documento
        </span>
      </div>
    </template>
    <!--Contenido de modal de | ID :
    {{ localVotacionId }}-->
    <b-form id="frm-add-edit-proceso-electoral-gasto">
      <div class="row pt-1">
        <div class="col-md-12">
          <label class="font-weight-bold">Fecha</label>
          <b-form-datepicker
            id="datepicker-1"
            class="form-control"
            v-model="procesoElectoralGasto.fechaRegistro"
            required
          >
          </b-form-datepicker>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Tipo de Documento</label>
          <select
            class="form-control"
            v-model="procesoElectoralGasto.tipoDocumentoGastoParamId"
            required
          >
            <option :value="null">- Seleccionar -</option>
            <option
              v-for="tipoDocumento in tipoDocumentos"
              :value="tipoDocumento.parametroId"
              :key="tipoDocumento.parametroId"
              >{{ tipoDocumento.descripcion }}</option
            >
          </select>
        </div>
      </div>
      <div v-if="requiredNro" class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Nro. Documento</label>
          <b-form-input
            id="input-1"
            v-model="procesoElectoralGasto.numeroDocumentoGasto"
            placeholder="Nro."
            required
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Documento</label>
          <b-form-file
            v-model="procesoElectoralGasto.documento"
            placeholder="Seleccionar archivo..."
          ></b-form-file>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Región</label>
          <b-form-input
            id="input-2"
            v-model="procesoElectoralGasto.region"
            placeholder=""
            required
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label
            class="font-weight-bold"
            v-if="
              procesoElectoralGasto.tipoDocumentoGastoParamId == null ||
                requiredNro
            "
            >Proveedor</label
          >
          <label
            class="font-weight-bold"
            v-if="
              procesoElectoralGasto.tipoDocumentoGastoParamId != null &&
                !requiredNro
            "
            >Declarante (persona)</label
          >
          <b-form-input
            id="input-3"
            v-model="procesoElectoralGasto.proveedor"
            placeholder=""
            required
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Concepto</label>
          <b-form-input
            id="input-4"
            v-model="procesoElectoralGasto.concepto"
            placeholder="Movilidad, Courier, Materiales, etc."
            required
          ></b-form-input>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Importe</label>
          <b-form-input
            id="input-5"
            v-model="procesoElectoralGasto.importe"
            placeholder="S/."
          ></b-form-input>
        </div>
      </div>
      <!--Sección Administrador-->
      <div v-if="codigoRol == 'ADM'" class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Estado</label>
          <select
            class="form-control"
            v-model="procesoElectoralGasto.estadoAprobacion"
            required
          >
            <option :value="null">- Seleccionar -</option>
            <option value="PEN">Pendiente</option>
            <option value="APR">Aprobado</option>
            <option value="REC">Rechazado</option>
          </select>
        </div>
      </div>
      <div v-if="codigoRol == 'ADM'" class="row pt-3">
        <div class="col-md-12">
          <label class="font-weight-bold">Observación</label>
          <b-form-input
            id="input-6"
            v-model="procesoElectoralGasto.observacionesAprobacion"
            placeholder=""
          ></b-form-input>
        </div>
      </div>
      <!--Fin Sección Administrador-->
      <div class="row pt-3">
        <div class="col-md-2" style="align-self: center;">
          <span class="font-weight-bold">Eliminar</span>
        </div>
        <div class="col-md-10">
          <b-form-checkbox
            id="checkbox-1"
            size="lg"
            v-model="procesoElectoralGasto.esEliminado"
          >
          </b-form-checkbox>
        </div>
      </div>
    </b-form>
    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="handleModalClose()"
      >
        <i class="fa fa-ban"></i> Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary font-weight-bold"
        @click.prevent="doSave()"
      >
        <i class="fa fa-save"></i> Guardar
      </button>
    </template>
  </b-modal>
</template>

<style></style>

<script>
export default {
  name: "add-edit-proceso-electoral-gasto",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    procesoElectoralGastoId: {
      type: Number,
    },
  },
  data: () => ({
    mostrarModal: true,
    codigoRol: "",
    procesoElectoralGasto: {
      procesoElectoralGastoId: null,
      procesoElectoralId: null,
      usuarioRegistroId: null,
      fechaRegistro: null,
      tipoDocumentoGastoParamId: null,
      numeroDocumentoGasto: "",
      region: "",
      proveedor: "",
      concepto: "",
      importe: null,
      estadoAprobacion: "",
      observacionesAprobacion: "",
      fechaEstadoAprobacion: null,
      usuarioAprobacionId: null,
      esEliminado: false,
      documento: null,
    },
    tipoDocumentos: [],
    requiredNro: false,
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }

      /*var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }*/

      this.mostrarModal = false;
    },
    doSave: async function() {
      if (!(await this.sweetConfirm("¿Desea guardar los datos?"))) {
        return;
      }

      if (!this.isFormValid("frm-add-edit-proceso-electoral-gasto")) {
        return this.sweetAlert(
          "Hay campos con valores incorrectos. Revise los datos ingresados."
        );
      }

      var model = {
        ...this.procesoElectoralGasto,
      };

      var formData = this.convertModelToFormData(model);

      this.estaGuardando = true;
      await this.$http
        .post(
          this.apiRoute(
            "ElectoralProcessSpending",
            "AddEditProcesoElectoralGasto"
          ),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            this.mostrarModal = false;
          }
        }, this.mostrarMensajeExcepcionVueResource);

      this.estaGuardando = false;
    },
    fetchProcesoElectoralGasto: function(procesoElectoralGastoId) {
      if (procesoElectoralGastoId) {
        this.$http
          .get(
            this.apiRoute(
              "ElectoralProcessSpending",
              "GetProcesoElectoralGastoById"
            ),
            {
              params: {
                ProcesoElectoralGastoId: procesoElectoralGastoId,
              },
            }
          )
          .then(function(response) {
            this.procesoElectoralGasto = response.body.data;
          });
      }
    },
    fetchParametroDocumentos: function() {
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: "TIPO_DOCUMENTO_GASTO",
          },
        })
        .then(function(response) {
          var parameterResponse = response.body.data;

          for (var i = 0; i < parameterResponse.length; i++) {
            var item = parameterResponse[i];
            if (item.propiedades) {
              item.propiedades = JSON.parse(item.propiedades);
            } else {
              item.propiedades = {};
            }
          }

          this.tipoDocumentos = parameterResponse;
        });
    },
    getPropiedadTipoDocumento: function(tipoDocumentoGastoParamId) {
      var tipoDocumento = this.tipoDocumentos.find(
        (x) => x.parametroId == tipoDocumentoGastoParamId
      );

      if (tipoDocumento != null) {
        this.requiredNro = tipoDocumento.propiedades.requiereNroDocumento;
      }

      return this.requiredNro;
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
    "procesoElectoralGasto.tipoDocumentoGastoParamId": function(
      newValue,
      oldValue
    ) {
      this.getPropiedadTipoDocumento(newValue);
    },
  },
  mounted: function() {
    this.mostrarModal = this.value;
    this.codigoRol = this.$session.get("CodigoRol");
    this.procesoElectoralGasto.procesoElectoralGastoId = this.procesoElectoralGastoId;
    this.procesoElectoralGasto.procesoElectoralId = this.$session.get(
      "ProcesoElectoralId"
    );
    this.initParsley();
    this.fetchParametroDocumentos();
    this.fetchProcesoElectoralGasto(
      this.procesoElectoralGasto.procesoElectoralGastoId
    );
  },
  destoyed: function() {},
};
</script>
